import * as React from 'react';
import Seo from "../components/seo";
import { graphql, Link } from "gatsby";
import Layout from '../components/layout';

export class IndexPage extends React.Component {
  render() {
    const { data } = this.props;
    const posts = data.allMarkdownRemark.nodes;

    return (
      <Layout>
        <Seo title="Micadep's blog." />
        <h2 className="text-4xl font-bold mb-6 border-b pb-4 text-blue-800">Latest Blog Posts</h2>
        <div className="grid grid-cols-1 gap-8">
          {posts.map((post) => (
            <div key={post.id} className="bg-white shadow-md rounded-lg overflow-hidden border-2 border-blue-200 transition-all duration-300 hover:shadow-lg hover:border-blue-400">
              <div className="p-6">
                <Link to={post.fields.slug} className="text-blue-700 hover:text-blue-900">
                  <h3 className="text-2xl font-bold mb-2">{post.frontmatter.title}</h3>
                </Link>
                <small className="text-blue-600 block mb-4">Published on {post.frontmatter.date}</small>
                <p className="text-gray-700 mb-4">{post.excerpt}</p>
                <Link to={post.fields.slug} className="inline-block bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition-colors duration-300">
                  Read More
                </Link>
              </div>
            </div>
          ))}
        </div>
      </Layout>
    );
  }
}

export default IndexPage;

export const query = graphql`
  {
    allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      limit: 100
    ) {
      nodes {
        frontmatter {
          title
          date(formatString: "MMMM Do, YYYY")
        }
        id
        fields {
          slug
        }
        excerpt(pruneLength: 100)
      }
    }
  }
`;
